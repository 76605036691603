{
	"name": "peerjs",
	"version": "1.5.2",
	"keywords": [
		"peerjs",
		"webrtc",
		"p2p",
		"rtc"
	],
	"description": "PeerJS client",
	"homepage": "https://peerjs.com",
	"bugs": {
		"url": "https://github.com/peers/peerjs/issues"
	},
	"repository": {
		"type": "git",
		"url": "https://github.com/peers/peerjs"
	},
	"license": "MIT",
	"contributors": [
		"Michelle Bu <michelle@michellebu.com>",
		"afrokick <devbyru@gmail.com>",
		"ericz <really.ez@gmail.com>",
		"Jairo <kidandcat@gmail.com>",
		"Jonas Gloning <34194370+jonasgloning@users.noreply.github.com>",
		"Jairo Caro-Accino Viciana <jairo@galax.be>",
		"Carlos Caballero <carlos.caballero.gonzalez@gmail.com>",
		"hc <hheennrryy@gmail.com>",
		"Muhammad Asif <capripio@gmail.com>",
		"PrashoonB <prashoonbhattacharjee@gmail.com>",
		"Harsh Bardhan Mishra <47351025+HarshCasper@users.noreply.github.com>",
		"akotynski <aleksanderkotbury@gmail.com>",
		"lmb <i@lmb.io>",
		"Jairooo <jairocaro@msn.com>",
		"Moritz Stückler <moritz.stueckler@gmail.com>",
		"Simon <crydotsnakegithub@gmail.com>",
		"Denis Lukov <denismassters@gmail.com>",
		"Philipp Hancke <fippo@andyet.net>",
		"Hans Oksendahl <hansoksendahl@gmail.com>",
		"Jess <jessachandler@gmail.com>",
		"khankuan <khankuan@gmail.com>",
		"DUODVK <kurmanov.work@gmail.com>",
		"XiZhao <kwang1imsa@gmail.com>",
		"Matthias Lohr <matthias@lohr.me>",
		"=frank tree <=frnktrb@googlemail.com>",
		"Andre Eckardt <aeckardt@outlook.com>",
		"Chris Cowan <agentme49@gmail.com>",
		"Alex Chuev <alex@chuev.com>",
		"alxnull <alxnull@e.mail.de>",
		"Yemel Jardi <angel.jardi@gmail.com>",
		"Ben Parnell <benjaminparnell.94@gmail.com>",
		"Benny Lichtner <bennlich@gmail.com>",
		"fresheneesz <bitetrudpublic@gmail.com>",
		"bob.barstead@exaptive.com <bob.barstead@exaptive.com>",
		"chandika <chandika@gmail.com>",
		"emersion <contact@emersion.fr>",
		"Christopher Van <cvan@users.noreply.github.com>",
		"eddieherm <edhermoso@gmail.com>",
		"Eduardo Pinho <enet4mikeenet@gmail.com>",
		"Evandro Zanatta <ezanatta@tray.net.br>",
		"Gardner Bickford <gardner@users.noreply.github.com>",
		"Gian Luca <gianluca.cecchi@cynny.com>",
		"PatrickJS <github@gdi2290.com>",
		"jonnyf <github@jonathanfoss.co.uk>",
		"Hizkia Felix <hizkifw@gmail.com>",
		"Hristo Oskov <hristo.oskov@gmail.com>",
		"Isaac Madwed <i.madwed@gmail.com>",
		"Ilya Konanykhin <ilya.konanykhin@gmail.com>",
		"jasonbarry <jasbarry@me.com>",
		"Jonathan Burke <jonathan.burke.1311@googlemail.com>",
		"Josh Hamit <josh.hamit@gmail.com>",
		"Jordan Austin <jrax86@gmail.com>",
		"Joel Wetzell <jwetzell@yahoo.com>",
		"xizhao <kevin.wang@cloudera.com>",
		"Alberto Torres <kungfoobar@gmail.com>",
		"Jonathan Mayol <mayoljonathan@gmail.com>",
		"Jefferson Felix <me@jsfelix.dev>",
		"Rolf Erik Lekang <me@rolflekang.com>",
		"Kevin Mai-Husan Chia <mhchia@users.noreply.github.com>",
		"Pepijn de Vos <pepijndevos@gmail.com>",
		"JooYoung <qkdlql@naver.com>",
		"Tobias Speicher <rootcommander@gmail.com>",
		"Steve Blaurock <sblaurock@gmail.com>",
		"Kyrylo Shegeda <shegeda@ualberta.ca>",
		"Diwank Singh Tomer <singh@diwank.name>",
		"Sören Balko <Soeren.Balko@gmail.com>",
		"Arpit Solanki <solankiarpit1997@gmail.com>",
		"Yuki Ito <yuki@gnnk.net>",
		"Artur Zayats <zag2art@gmail.com>"
	],
	"funding": {
		"type": "opencollective",
		"url": "https://opencollective.com/peer"
	},
	"collective": {
		"type": "opencollective",
		"url": "https://opencollective.com/peer"
	},
	"files": [
		"dist/*"
	],
	"sideEffects": [
		"lib/global.ts",
		"lib/supports.ts"
	],
	"main": "dist/bundler.cjs",
	"module": "dist/bundler.mjs",
	"browser-minified": "dist/peerjs.min.js",
	"browser-unminified": "dist/peerjs.js",
	"browser-minified-cbor": "dist/serializer.cbor.mjs",
	"browser-minified-msgpack": "dist/serializer.msgpack.mjs",
	"types": "dist/types.d.ts",
	"engines": {
		"node": ">= 14"
	},
	"targets": {
		"types": {
			"source": "lib/exports.ts"
		},
		"main": {
			"source": "lib/exports.ts",
			"sourceMap": {
				"inlineSources": true
			}
		},
		"module": {
			"source": "lib/exports.ts",
			"includeNodeModules": [
				"eventemitter3"
			],
			"sourceMap": {
				"inlineSources": true
			}
		},
		"browser-minified": {
			"context": "browser",
			"outputFormat": "global",
			"optimize": true,
			"engines": {
				"browsers": "chrome >= 83, edge >= 83, firefox >= 80, safari >= 15"
			},
			"source": "lib/global.ts"
		},
		"browser-unminified": {
			"context": "browser",
			"outputFormat": "global",
			"optimize": false,
			"engines": {
				"browsers": "chrome >= 83, edge >= 83, firefox >= 80, safari >= 15"
			},
			"source": "lib/global.ts"
		},
		"browser-minified-cbor": {
			"context": "browser",
			"outputFormat": "esmodule",
			"isLibrary": true,
			"optimize": true,
			"engines": {
				"browsers": "chrome >= 83, edge >= 83, firefox >= 102, safari >= 15"
			},
			"source": "lib/dataconnection/StreamConnection/Cbor.ts"
		},
		"browser-minified-msgpack": {
			"context": "browser",
			"outputFormat": "esmodule",
			"isLibrary": true,
			"optimize": true,
			"engines": {
				"browsers": "chrome >= 83, edge >= 83, firefox >= 102, safari >= 15"
			},
			"source": "lib/dataconnection/StreamConnection/MsgPack.ts"
		}
	},
	"scripts": {
		"contributors": "git-authors-cli --print=false && prettier --write package.json && git add package.json package-lock.json && git commit -m \"chore(contributors): update and sort contributors list\"",
		"check": "tsc --noEmit && tsc -p e2e/tsconfig.json --noEmit",
		"watch": "parcel watch",
		"build": "rm -rf dist && parcel build",
		"prepublishOnly": "npm run build",
		"test": "jest",
		"test:watch": "jest --watch",
		"coverage": "jest --coverage --collectCoverageFrom=\"./lib/**\"",
		"format": "prettier --write .",
		"format:check": "prettier --check .",
		"semantic-release": "semantic-release",
		"e2e": "wdio run e2e/wdio.local.conf.ts",
		"e2e:bstack": "wdio run e2e/wdio.bstack.conf.ts"
	},
	"devDependencies": {
		"@parcel/config-default": "^2.9.3",
		"@parcel/packager-ts": "^2.9.3",
		"@parcel/transformer-typescript-tsc": "^2.9.3",
		"@parcel/transformer-typescript-types": "^2.9.3",
		"@semantic-release/changelog": "^6.0.1",
		"@semantic-release/git": "^10.0.1",
		"@swc/core": "^1.3.27",
		"@swc/jest": "^0.2.24",
		"@types/jasmine": "^4.3.4",
		"@wdio/browserstack-service": "^8.11.2",
		"@wdio/cli": "^8.11.2",
		"@wdio/globals": "^8.11.2",
		"@wdio/jasmine-framework": "^8.11.2",
		"@wdio/local-runner": "^8.11.2",
		"@wdio/spec-reporter": "^8.11.2",
		"@wdio/types": "^8.10.4",
		"http-server": "^14.1.1",
		"jest": "^29.3.1",
		"jest-environment-jsdom": "^29.3.1",
		"mock-socket": "^9.0.0",
		"parcel": "^2.9.3",
		"prettier": "^3.0.0",
		"semantic-release": "^21.0.0",
		"ts-node": "^10.9.1",
		"typescript": "^5.0.0",
		"wdio-geckodriver-service": "^5.0.1"
	},
	"dependencies": {
		"@msgpack/msgpack": "^2.8.0",
		"cbor-x": "1.5.4",
		"eventemitter3": "^4.0.7",
		"peerjs-js-binarypack": "^2.1.0",
		"webrtc-adapter": "^8.0.0"
	},
	"alias": {
		"process": false,
		"buffer": false
	}
}
